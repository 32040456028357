import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/Layout"

const SingleProject = () => {
  return (
    <div>
      <Layout>
        <div className="flex text-white flex-col items-center justify-center py-24 px-6 lg:px-16 case_study_bg lg:h-96">
          <h1 className="text-3xl lg:text-6xl font-bold text-center">
            BETKING
          </h1>
          <p className="lg:w-100 text-xl font-thin pt-8">
            Betking seeks for a perfect point to break into the Nigerian market,
            which has been dominated by other big players.
          </p>
        </div>
        <div className="flex items-center justify-center py-8 px-6 lg:px-32">
          <article className=" font-thin py-16 w-full ">
            <div className="flex lg:flex-row flex-col">
              <div className="flex flex-col w-full lg:w-1/2  pr-0 lg:pr-12">
                <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/betking7.jpeg"
                  placeholder="blurred"
                  className="my-4"
                />
                <h1 className="text-xl font-bold items-center ">CHALLENGE</h1>

                <div className="">
                  <p className="font-light">• To create awareness</p>{" "}
                  <p className="font-light">• Drive trial and repeat trail.</p>
                  <p className="font-light">
                    • Identify and drive emotional connection with agents.
                  </p>
                </div>
              </div>

              <div className="flex flex-col w-full lg:w-1/2  pt-8 lg:pt-0">
                <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/betking5.jpeg"
                  placeholder="blurred"
                  className="my-4"
                />
                <h1 className="text-xl font-bold items-center ">CONVERSION</h1>

                <div className="">
                  <p className="font-light">
                    {" "}
                    • Use of popular footballer – Jayjay Okocha as the brand
                    ambassador.
                  </p>{" "}
                  <p className="font-light">
                    • Popular OAPs that resonated with the TG were used to drive
                    brand message and connection.{" "}
                  </p>
                  <p className="font-light">
                    • Using our in-house tool, Audience look, we deployed OOH
                    formats at relevant routes.
                  </p>
                  <p className="font-light">
                    • Invitation of Betking agents to the launch where they were
                    treated like kings.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex lg:flex-row flex-col pt-12">
              <div className="flex flex-col w-full lg:w-1/2 pr-0 lg:pr-12">
                <h1 className="text-xl font-bold items-center ">RESULT</h1>
                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/betking3.gif"
                  placeholder="blurred"
                  className="my-4"
                /> */}
                <div className="">
                  <p className="font-light">
                    {" "}
                    User traffic within the first month of the campaign grew by
                    67.3% delivering a conversion rate increase of 45.3%. The
                    customer fan base skyrocketed from 200 active customers
                    before brand launch in 2017 to 300,000+ active customers by
                    the end of 2018.
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
      </Layout>
    </div>
  )
}

export default SingleProject
